import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import colors from '../../theme/colors';
import HowListContainer from '../../components/services/HowListContainer';

const migrationComplexityWrapper = {
    minHeight: '232px',
    maxWidth: '1440px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignText: 'center',
    padding: '50px 24px',
};
const serviceIntroductionData = {
    title: 'Nubi Migration Service',
    firstParagraph: 'Servicio de migración de aplicaciones y datos realizado por nuestros ingenieros. Definí qué, despreocupate del cómo.',
    gatsbyImg: (<StaticImage src="../../images/migration.svg" alt="Almacenamiento" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};
const homeConsultationProps = {
    mainContainer: {
        background: colors.burgundy,
        paddingTop: '0px',
    },
    consultationContainer: {
        borderRadius: '10px',
        border: `1px solid ${colors.darkViolet}`,
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
};

const howWeDoItProps = {
    items: [
        {
            id: '1.',
            text: 'Realizamos un relevamiento integral de la infraestructura actual y esquemas de conectividad.',
        },
        {
            id: '2.',
            text: 'Nos ponemos en contacto con tus proveedores de software.',
        },
        {
            id: '3.',
            text: 'Hacemos un grupo de Telegram para una comunicación ágil.',
        },
        {
            id: '4.',
            text: 'Planificamos la mejor manera de transferir los datos, para evitar cortes en la operatoria.',
        },
        {
            id: '5.',
            text: 'Nos adaptamos 100% a tus tiempos.',
        },
    ],
};

const NubiMigrationService = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: colors.darkViolet,
            }}
        >
            <Box sx={{ ...migrationComplexityWrapper }}>
                <Typography
                    variant="subSectionH2"
                    sx={(theme) => ({ [theme.breakpoints.down('lg')]: { display: 'none' } })}
                    style={{ fontFamily: 'Nunito', fontSize: '28px' }}
                >
                    Lo que detiene muchas veces la adopción de la nube, es la complejidad de la migración.
                </Typography>
                <Typography
                    variant="sectionH2"
                    sx={(theme) => ({ [theme.breakpoints.down('lg')]: { display: 'none' } })}
                    style={{ fontFamily: 'Nunito', fontSize: '28px' }}
                    mb={2}
                >
                    Nosotros nos encargamos de todo...
                </Typography>
                <Typography
                    variant="sectionH2"
                    sx={(theme) => ({ [theme.breakpoints.up('lg')]: { display: 'none' } })}
                    style={{ fontFamily: 'Nunito', fontSize: '28px' }}
                    mb={2}
                >
                    Lo que detiene muchas veces la adopción de la nube, es la complejidad de la migración. Nosotros nos encargamos de todo...
                </Typography>
                <Typography variant="sectionH2" style={{ fontFamily: 'Nunito', color: colors.green }}>
                    ¡Gratis!
                </Typography>
            </Box>
        </Box>
        <HowListContainer howWeDoItProps={howWeDoItProps} />
        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default NubiMigrationService;
