import React from 'react';
import {
    Grid, Box, Typography, Button,
} from '@mui/material';
import { Link } from 'gatsby';
import colors from '../../theme/colors';

const introductionWrapper = {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '10px',
    maxWidth: '1440px',
    width: '100%',
};
const introductionImageContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const columnWrapper = {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

const buttonContainer = {
    width: '234px',
    height: '48px',
};

const textCustomStyle = {
    fontWeight: 400,
    fontFamily: 'Nunito',
    maxWidth: '687px',
    lineHeight: '33.6px',
};

const ServiceIntroduction = ({ serviceIntroductionData }) => (
    <Box
        style={{
            backgroundColor: colors.burgundy,
            display: 'flex',
            justifyContent: 'center',
            boxShadow: 'inset 0px -8px 20px 2px rgba(0, 0, 0, 0.15)',
        }}
    >
        <Box sx={{ ...introductionWrapper }}>
            <Grid
                container
                sx={(theme) => ({
                    padding: '145px 136px 60px',
                    boxSizing: 'border-box',
                    [theme.breakpoints.down('sm')]: {
                        padding: '109px 24px 24px',
                    },
                })}
            >
                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            textAlign: 'center',
                            alignItems: 'center',
                        },
                        ...columnWrapper,

                    })}
                >
                    <Typography variant="sectionH2">
                        {serviceIntroductionData.title}
                    </Typography>
                    <Typography variant="h3" style={{ ...textCustomStyle }} mt={2}>
                        {serviceIntroductionData.firstParagraph}
                    </Typography>
                    <Typography variant="h3" style={{ ...textCustomStyle }}>
                        {serviceIntroductionData.secondParagraph}
                        {serviceIntroductionData?.secondParagraphLink
                        && (
                            <Typography
                                component={Link}
                                to={serviceIntroductionData.secondParagraphLinkUrl}
                                variant="h3"
                                style={{
                                    ...textCustomStyle,
                                    display: 'inline',
                                    color: colors.green,
                                    textDecoration: 'none',
                                }}
                            >
                                {serviceIntroductionData.secondParagraphLink}
                            </Typography>
                        )}
                    </Typography>
                    {serviceIntroductionData.isButton && (
                        <Box sx={{ ...buttonContainer }} mt={3}>
                            {serviceIntroductionData.externalLinkButton
                                ? serviceIntroductionData.externalLinkButton
                                : (
                                    <Button
                                        component={Link}
                                        to={serviceIntroductionData.buttonLinkTo}
                                        variant="contained"
                                        aria-label={serviceIntroductionData.buttonText || 'Crear ahora'}
                                        style={{ width: '100%', height: '100%' }}
                                    >
                                        {serviceIntroductionData.buttonText || 'Crear ahora'}
                                    </Button>
                                )}

                        </Box>
                    )}
                </Grid>
                <Grid
                    item
                    sm={4}
                    sx={(theme) => ({
                        [theme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                        ...introductionImageContainer,
                    })}
                >
                    {serviceIntroductionData.gatsbyImg}
                </Grid>
            </Grid>
        </Box>
    </Box>
);

export default ServiceIntroduction;
