import React from 'react';
import {
    Grid, Box, Typography,
} from '@mui/material';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '36px 136px 0px',
    minHeight: '415px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
};
const infoContainer = {
    minHeight: '250px',
};
const customH1 = {
    fontWeight: 600,
    fontFamily: 'Nunito',
    fontSize: '36px',
    lineHeight: '51px',
    color: colors.green,
    paddingRight: '22px',
};
const howWeDoItItemContainer = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
};

const HowListContainer = ({ howWeDoItProps }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            background: colors.burgundy,
            boxShadow: 'inset 0px 8px 20px -8px rgba(0, 0, 0, 0.15)',
        }}
        style={howWeDoItProps?.style && { ...howWeDoItProps.style }}
    >
        <Box
            sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px 0px 24px' }, ...infoWrapper })}
        >
            <Box
                style={{ textAlign: 'left', width: '100%' }}
                sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '24px' } })}
            >
                <Typography variant="sectionH2" mb={2}>
                    Cómo lo hacemos
                </Typography>
            </Box>
            <Grid container sx={{ ...infoContainer }}>
                {howWeDoItProps.items.map((item) => (
                    <Grid
                        item
                        xs={0}
                        lg={6}
                        sx={(theme) => ({ [theme.breakpoints.down('md')]: { marginBottom: '12px' }, ...howWeDoItItemContainer })}
                        pr={3}
                        key={item.text}
                    >
                        <Typography sx={{ ...customH1 }}>{item.id}</Typography>
                        <Typography variant="h4" style={{ fontWeight: 600, lineHeight: '29px' }}>
                            {item.text}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    </Box>
);

export default HowListContainer;
