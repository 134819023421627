import React from 'react';
import {
    Typography, Box, Grid, Button,
} from '@mui/material';
import { Link } from 'gatsby';
import consultationBackgroundMask from '../../images/consultation-background-mask.svg';
import colors from '../../theme/colors';

const mainContainer = {
    padding: '36px 136px',
    maxWidth: '1440px',
    width: '100%',
};
const consultationContainer = {
    backgroundColor: colors.violet,
    borderRadius: '10px',
};
const consultationMask = {
    background: `url(${consultationBackgroundMask})`,
    width: '100%',
    height: '100%',
    padding: '42px',
};
const consultationTitle = {
    fontSize: 26,
    weight: 700,
};
const consultationText = {
    color: colors.lightGreen,
};
const consultationButtonContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
const consultationButton = {
    backgroundColor: colors.green,
    color: colors.white,
    width: 261,
    height: 40,
};

const HomeConsultation = ({ homeConsultationProps }) => (
    <Box
        sx={{
            backgroundColor: colors.darkViolet,
            display: 'flex',
            justifyContent: 'center',
        }}
        style={homeConsultationProps && { ...homeConsultationProps.mainContainer }}
    >
        <Box
            sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 0px' }, ...mainContainer })}
        >
            <Box
                sx={(theme) => ({ [theme.breakpoints.down('sm')]: { margin: '0 24px' }, ...consultationContainer })}
                style={homeConsultationProps && { ...homeConsultationProps.consultationContainer }}
            >
                <Box sx={{ ...consultationMask }}>
                    <Typography
                        mb={2}
                        variant="h4"
                        style={{ lineHeight: '34px', fontFamily: 'Montserrat' }}
                        sx={(theme) => ({ [theme.breakpoints.up('lg')]: { maxWidth: '410px' }, ...consultationTitle })}
                    >
                        ¡Hacé tu consulta completamente gratis!
                    </Typography>
                    <Grid container>
                        <Grid item lg={8} xs={12}>
                            <Typography
                                variant="h4"
                                style={{ fontWeight: 400, lineHeight: '28px' }}
                                sx={(theme) => ({ [theme.breakpoints.up('lg')]: { width: '652px' }, ...consultationText })}
                            >
                                ¿Querés empezar con servicios en la nube pero no sabés qué necesitás?
                            </Typography>
                            <Typography
                                variant="h4"
                                style={{
                                    fontWeight: 400,
                                    lineHeight: '28px',
                                }}
                                sx={(theme) => ({
                                    [theme.breakpoints.up('lg')]: {
                                        width: '652px',
                                        '@media only screen and (min-width: 1200px) and (max-width:1300px)': {
                                            width: '500px',
                                        },
                                    },
                                    ...consultationText,
                                })}
                            >
                                Realizá tu consulta sin costo y nosotros te asesoramos. ¡Comenzá a innovar en minutos!
                            </Typography>
                        </Grid>
                        <Grid item lg={4} xs={12} mt={2} sx={{ ...consultationButtonContainer }}>
                            <Link to="/contact_us" style={{ textDecoration: 'none' }}>
                                <Button
                                    variant="contained"
                                    sx={{ ...consultationButton }}
                                >
                                    Contactá con especialista
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    </Box>
);

export default HomeConsultation;
